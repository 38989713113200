
// TODO Set environment variables without relying on the hostname.
// This is a hack
const environment = window.location.hostname.includes('pav.pet') ? 'prod' : 'dev'

const prodConfig = {
  apiUrl: 'https://admin-api.pav.pet',
  uploadApiUrl: 'https://public-api.pav.pet',
  region: 'ap-southeast-2',
  userPoolId: 'ap-southeast-2_iJD7HIXbK',
  userPoolWebClientId: '1avfflsetisstui9mpap1og9t3',
}

const devConfig = {
  apiUrl: 'https://admin-api.avanca.co',
  uploadApiUrl: 'https://public-api.avanca.co',
  region: 'ap-southeast-2',
  userPoolId: 'ap-southeast-2_NkAJ88ckS',
  userPoolWebClientId: '7hqccg1n5r1k3ge6tie0k7ea0g',
}

export const config = environment === 'prod' ? prodConfig : devConfig;
