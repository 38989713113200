// @ts-nocheck
import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import Loader from "./components/loader";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

import "./scss/style.scss";

const Layout = React.lazy(() => import("./components/layout"));
const ErrorPage = React.lazy(() => import("./views/notFound"));

const App = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <HashRouter>
      <React.Suspense fallback={Loader}>
        <Switch>
          <Route
            exact
            path="/404"
            render={(props) => <ErrorPage {...props} />}
          />
          <Route path="/" render={(props) => <Layout {...props} />} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        backgroundColor: "#2c2c34",
      }}
    >
      <AmplifyAuthenticator style={{ height: "500px" }}>
        <AmplifySignIn slot="sign-in" hideSignUp></AmplifySignIn>
      </AmplifyAuthenticator>
    </div>
  );
};

export default App;
