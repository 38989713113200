// @ts-nocheck
import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import Amplify, { Auth } from "aws-amplify";
import App from "./App";
import { config } from "./config";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import { icons } from "./assets/icons";

import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

React.icons = icons;

Amplify.configure({
  production: true,
  region: config.region,
  userPoolId: config.userPoolId,
  userPoolWebClientId: config.userPoolWebClientId,
});

const httpLink = createHttpLink({
  uri: config.apiUrl,
});

const authLink = setContext(async (_, { headers }) => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <App />
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
